import React, { useState } from 'react';
import axios from 'axios';
import './App.css';  // Import the CSS file

function App() {
    const [functionKey, setFunctionKey] = useState('');
    const [inputName, setInputName] = useState('');
    const [messages, setMessages] = useState([]);

    const handleSend = async () => {
        const url = 'https://chatter-box-poc.azurewebsites.net/api/http_trigger';
        const data = inputName;  // Assuming 'inputName' is the raw text string you want to send
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                'x-functions-key': functionKey
            }
        };
        try {
            await axios.post(url, data, config);            
        } catch (error) {
            console.error('Error calling Azure Function:', error);
        }
    };


    const handleGetMessages = async () => {
        const config = { 
            headers: {
                'Content-Type': 'text/plain',
                'x-functions-key': functionKey
            }
        };
        try {
            const functionUrl = 'https://chatter-box-poc.azurewebsites.net/api/get_latest_messages';
            const response = await axios.get(`${functionUrl}?code=${functionKey}`, config);

            setMessages(response.data);  // Assuming 'messages' is a state variable holding the array
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };
    return (
        <div className="container">
            <div>
                <label>
                    Function Key:
                    <input
                        type="text"
                        value={functionKey}
                        onChange={(e) => setFunctionKey(e.target.value)}
                    />
                </label>
            </div>
            <div className="input-group">
                <input
                    type="text"
                    value={inputName}
                    onChange={(e) => setInputName(e.target.value)}
                    placeholder="Enter your message"
                />
                <button onClick={handleSend}>Send</button>
            </div>
            <div>
                <button onClick={handleGetMessages}>Get Messages</button>
                <ul>
                    {messages.map((message, index) => (
                        <li key={index}>{message.Message}</li>  // Adjust depending on your message structure
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default App;
